import React, { Suspense } from 'react';
import { SWRConfig } from 'swr'
import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect
} from "react-router-dom";
import auth from './lib/auth';
import Loader from "./components/loader";
import {jsonFetcher} from "./lib/api";
import PrivateRoute from "./components/private-route";
import {loadPage} from "./lib/load-module";

const NotFound = loadPage(() => import("./pages/not-found"));
const Login = loadPage(() => import("./pages/login"));
const Logout = loadPage(() => import("./pages/logout"));
const Signup = loadPage(() => import("./pages/signup"));
const Admin = loadPage(() => import("./pages/admin"));
const Player = loadPage(() => import("./pages/player"));
const Layouts = loadPage(() => import("./pages/screens"));


function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/layouts" component={Layouts} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/signup" component={Signup} />
        <PrivateRoute path="/admin" component={Admin} />
        <PrivateRoute path="/player" component={Player} />
        <PrivateRoute path="/" component={() => {
            return auth.hasScope('user')
                ? <Redirect to="/admin" />
                : <Redirect to="/player" />
        }} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  )
}

function App() {
  return <Suspense fallback={<Loader />}>
    <SWRConfig
        value={{
          fetcher: jsonFetcher
        }}
    >
      <Routes />
    </SWRConfig>
  </Suspense>
}

export default App
