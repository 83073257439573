import auth from '../auth';

let _apiUrl: string
const apiUrl = (): string => {
    if (!_apiUrl) {
        const host = window.location.host

        if(host.indexOf('localhost') > -1) {
            _apiUrl = 'http://localhost:3000'
        }
        else if(host.indexOf('golfkong.com') > -1) {
            _apiUrl = `https://api.golfkong.com`
        }
        else {
            _apiUrl = 'http://localhost:3000'
            console.error('No api matching host...')
        }
    }

    return _apiUrl;
}

export class ApiError extends Error {
    isApiError: boolean;
    status: number;
    data: any;

    constructor(status: number, data: any) {
        super(`Request api failed with http code: ${status}`);

        this.status = status;
        this.data = data;
        this.isApiError = true;
        this.name = 'ApiError';
    }
}


const FILE_HOST = window.location.host.indexOf('localhost') > -1 ? 'http://localhost:3005' : 'https://fs.golfkong.com'

export const uploadFile = (file: any ): Promise<{url: string}> => {
    const formData = new FormData();
    formData.append('file', file);
    return fetch(`${FILE_HOST}/v1/upload`, {
        method: 'POST',
        body: formData
    }).then(r => {
        if (r.ok) {
            return r.json()
        }

        throw new Error("Couldn't upload file");
    })
};


export async function sendRequest(path: string, opts: RequestInit = {}): Promise<Response> {
    const headers = Object.assign({}, opts.headers || {}, {
        'Content-type': 'application/json; charset=UTF-8'
    });

    const token = auth.getToken();

    if(token) {
        // @ts-ignore
        headers['Authorization'] = token;
    }

    const response = await fetch(
        path,
        Object.assign(
            {method: 'POST'},
            opts,
            {headers, credentials: 'same-origin'}
        )
    );

    const isLogin = path.indexOf('/api/v1/auth/quick-access') > -1 || path.indexOf('/api/v1/auth/login')
    // TODO: better refresh
    if(response.status === 401 && !isLogin) {
        const user = auth.getUser();
        if(user && user.scope.indexOf('team') > -1) {
            await endpoints.auth.quickAccess({ accessCode: user.accessCode })

            return sendRequest(path, opts);
        }
    }

    return response;
}

type SendPayload<P,R> = (payload: P) => Promise<R>;
function makeSendPayload(method: 'POST' | 'PUT') {
    return <P, R>(url: string): SendPayload<P, R> => {
        return async (payload) => {
            const result = await sendRequest(url, {
                method,
                body: JSON.stringify(payload)
            })

            const { status } = result;
            const data = await result.json();

            if (status === 200) {
                return data;
            }

            throw new ApiError(status, data)
        }
    }
}

const put = makeSendPayload('PUT')
const post = makeSendPayload('POST')


function get<R>(url: string): () => Promise<R> {
    return async () => {
        const result = await sendRequest(url, {
            method: 'GET',
        })

        const { status } = result;
        const data = await result.json();

        if (status === 200) {
            return data;
        }

        throw new ApiError(status, data)
    }
}

function remove<R>(url: string): () => Promise<R> {
    return async () => {
        const result = await sendRequest(url, {
            method: 'DELETE',
        })

        const { status } = result;
        const data = await result.json();

        if (status === 200) {
            return data;
        }

        throw new ApiError(status, data)
    }
}


export const jsonFetcher = async function fetcher(url: string) {
    const result = await sendRequest(url, { method: 'GET' });

    const { status } = result;
    const data = await result.json();

    if (status === 200) {
        return data;
    }

    throw new ApiError(status, data)
};

export type ApiUser = {
    id: string
    fullName: string
    city: string
    email: string
}

export type ApiAuthenticatedUser = ApiUser & {
    scopes: string[]
}

export type ApiSignup = {
    fullName: string
    city: string
    email: string
    password: string
}

export type ApiLogin = {
    email: string
    password: string
}

export type LoginToken = {
    token: string
}


export type ApiCourseTee = {
    color: string,
    yards: number
}

export type ApiCourseHole = {
    handicap: number,
    number: number,
    parValue: number,
    type: "Man" | "Woman",
    tees: ApiCourseTee[]
}

export type ApiCourse = {
    id: string
    name: string
    holes: ApiCourseHole[]
    location: {
        city: string
        country: string
        province: string
    }
}
export type GameFormat = 'STROKE_PLAY' | 'TO_PAR'

export type ApiCreateEvent = {
    title: string
    description: string
    location: string
    gameFormat: GameFormat
    teamSize: number
    coverPhoto: string
    rounds: Array<{
        courseId: string
        startDate: string
        teeType: string
        teeColor: string
    }>
}

export type ApiUpdateEvent = {
    title: string
    description: string
    location: string
    coverPhoto: string
}

export interface ApiEventRound {
    courseId: string
    course: {
        name: string
        location: string
    }
    roundNumber: number
    start: string
}

export interface ApiEvent {
    id: string
    title: string
    slug: string
    description: string
    coverPhoto: string
    url: string
    gameFormat: GameFormat
    location: string
    organizerId: string
    rounds: Array<ApiEventRound>
    start: string
    end: string
    currentRound: number
    teamSize: number
    createdAt: string
    updatedAt: string
}

export interface ApiUpdateCurrentRound {
    roundNumber: number
}

export interface ApiCreateTeam {
    name: string
    players: string[]
}

export interface ApiTeam {
    id: string
    name: string
    accessCode: string
    scores: number[]
    players: string[]
}

export interface ApiTeamUpdateScores {
    scores: {
       holeNumber: number
       strokes: number
       roundNumber: number
    }[]
}
export const Courses = {
    getAll: () => `${apiUrl()}/api/v1/courses`,
    getOne: (courseId: string) => `${apiUrl()}/api/v1/courses/${courseId}`
}

export const Events = {
    listMyEvents: () => `${apiUrl()}/api/v1/me/tournaments`,
    getOne: (id: string) => `${apiUrl()}/api/v1/tournaments/${id}`,
    getTeamsByEventId: (eventId: string) => `${apiUrl()}/api/v1/tournaments/${eventId}/teams`,
}

export const Teams = {
    getOne: (id: string) => `${apiUrl()}/api/v1/teams/${id}`,
}

export const Leaderboards = {
    getBySlug: (slug: string) => `${apiUrl()}/api/v1/leaderboards?eventSlug=${slug}`
}

export const endpoints = {
    auth: {
        me: get<ApiAuthenticatedUser>(`${apiUrl()}/api/v1/users/me`),
        login: post<ApiLogin, LoginToken>(`${apiUrl()}/api/v1/auth/login`),
        signup: post<ApiSignup, {}>(`${apiUrl()}/api/v1/users`),
        quickAccess: post<{accessCode: string}, LoginToken>(`${apiUrl()}/api/v1/auth/quick-access`)
    },
    courses: {
        getAll: get<ApiCourse>(`${apiUrl()}/api/v1/courses`)
    },
    events: {
        create: post<ApiCreateEvent, ApiEvent>(`${apiUrl()}/api/v1/tournaments`),
        update: (tournamentId: string) => put<ApiUpdateEvent, ApiEvent>(`${apiUrl()}/api/v1/tournaments/${tournamentId}`),
        addTeam: (eventId: string) => post<ApiCreateTeam, ApiTeam>(`${apiUrl()}/api/v1/tournaments/${eventId}/teams`),
        updateCurrentRound: (eventId: string) => post<ApiUpdateCurrentRound, ApiEvent>(`${apiUrl()}/api/v1/tournaments/${eventId}/current-round`),
        delete: (eventId: string) => remove<any>(`${apiUrl()}/api/v1/tournaments/${eventId}`)
    },
    teams: {
        delete: (teamId: string) => remove<any>(`${apiUrl()}/api/v1/teams/${teamId}`),
        update: (teamId: string) => put<ApiCreateTeam, ApiTeam>(`${apiUrl()}/api/v1/teams/${teamId}`),
        updateScores: (teamId: string) => post<ApiTeamUpdateScores, ApiTeam>(`${apiUrl()}/api/v1/teams/${teamId}/scores`)
    }
}