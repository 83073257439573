const AUTH_TOKEN = 'GK_AUTH_TOKEN';
const AUTH_USER = 'GK_AUTH_USER';

class Auth {
    constructor() {
        this.hasScope = this.hasScope.bind(this);

    }
    storeToken(token) {
        localStorage.setItem(AUTH_TOKEN, token);
    }
    getToken() {
        return localStorage.getItem(AUTH_TOKEN);
    }
    storeUser(user) {
        localStorage.setItem(AUTH_USER, JSON.stringify(user));
    }
    getUser() {
        const user = localStorage.getItem(AUTH_USER);

        return (user === null) ? null : JSON.parse(user);
    }
    hasScope(scope) {
        const user = this.getUser();

        if(user === null) {
            return false;
        }

        return user.scope.indexOf(scope) > -1;
    }
    isAuthenticated() {
        return this.getToken() !== null;
    }
    clear() {
        localStorage.removeItem(AUTH_TOKEN)
        localStorage.removeItem(AUTH_USER)
    }
}


const auth = new Auth();

export default auth;